<template>
  <div class="window-height-footer">
    <div class="wrapper">
      <div class="item">
        <div class="title">iThermAI B.V.</div>
        <p>
          Klein Dalenstraat 45<br />
          3020 Herent, Belgium<br /><br />
          <strong>Phone:</strong> +32 489 00 2283<br />
          <strong>Email:</strong> support@iThermai.com<br />
          <strong>VAT:</strong> BE 0766.252.389<br />
        </p>
      </div>
    </div>

    <div class="wrapper copyright">
      <div class="item">
        &copy; Copyright <strong><span>iThermAI</span></strong
        >. All Rights Reserved
        <div>Last Update: {{ time }}</div>
      </div>
      <div class="item">
        <a href="https://twitter.com/iThermAI"
          ><q-icon :name="mdiTwitter" class="icons"
        /></a>
        <a href="https://www.youtube.com/channel/UCqYFUeC1E_uYDNkixaFBUsQ"
          ><q-icon :name="mdiYoutube" class="icons"
        /></a>
        <a href="https://linkedin.com/company/iThermAI"
          ><q-icon :name="mdiLinkedin" class="icons"
        /></a>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiTwitter, mdiLinkedin, mdiYoutube } from "@quasar/extras/mdi-v6";
export default {
  setup() {
    return {
      time: process.env.VUE_APP_TIME + " CET",
      mdiTwitter,
      mdiLinkedin,
      mdiYoutube,
    };
  },
};
</script>

<style scoped>
.window-height-footer {
  background-color: #140403d8;
  background-size: contain;
  color: rgb(36, 35, 35);
  min-height: 30vh;
  color: white;
  width: 100%;
}
.wrapper {
  padding-right: 10%;
  padding-left: 10%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}
.item {
  padding: 15px;
}
a {
  color: rgb(211, 193, 171);
  text-decoration: none;
}
.title {
  font-size: 16px;
  font-weight: bold;
  color: rgb(228, 191, 147);
}
.angles {
  color: rgb(228, 211, 189);
}
.item p {
  font-size: 13px;
  color: rgb(237, 221, 202);
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  padding: 5px;
  padding-left: 10px;
}
.copyright {
  font-size: 16px;
  color: rgb(211, 193, 171);
  align-items: center;
}
.icons {
  margin-right: 8px;
  margin-left: 8px;
  width: 40px;
  height: 40px;
  font-size: 45px;
}
</style>
